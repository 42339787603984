/* ----- Footer ----- */
@import "../Theme.scss";

.footer {
  background-image: url("./BackgroundImages/footer.png");
  background-color: #041731;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 550px;

  @include flexbox;
  flex-direction: column;

  .footer__top {
    @include flexbox;
    // background-color: lightcoral;
    height: 60%;
    width: 80%;

    .footer__top__links {
      // background-color: lightgrey;
      height: 100%;
      width: 65%;

      @include flexbox;
      justify-content: space-between;

      .footer__top__link {
        height: 100%;
        width: 200px;
      }

      .footer__top__products {
        // background-color: lightsalmon;

        .footer__top__link__social {
          @include flexbox;
          justify-content: space-between;
          //   background-color: #fff;
          height: 75%;
          img {
            display: block;
            height: 40px;
          }
        }
      }

      .footer__top__link__heading {
        @include flexbox;
        justify-content: flex-start;
        padding: 5px 0px;
        border-bottom: 2px solid rgba($color: $lightBlue, $alpha: 0.5);
        p {
          color: $white;
          font-size: 22px;
        }
      }
      .footer__top__link__body {
        .footerlink {
          text-decoration: none;
          color: $white;
          font-size: 14px;
        }
      }
    }

    .footer__top__login {
      // background-color: lightgreen;
      height: 100%;
      width: 35%;

      .footer__top__login__heading {
        @include flexbox;
        justify-content: flex-end;
        // background-color: red;
        height: 15%;
        p {
          margin-left: 14px;
          color: $white;
          font-size: 20px;
        }
      }

      .footer__top__login__text {
        // background-color: blue;
        width: 100%;
        height: 55%;
        @include flexbox;
        align-items: flex-end;
        flex-direction: column;

        input {
          width: 80%;
          padding: 10px;
          margin: 10px 0px;
          border: none;
          border-radius: 5px;
          font-size: 16px;
        }
      }
      .footer__top__login__button {
        // background-color: purple;
        width: 100%;
        height: 30%;
        @include flexbox;
        justify-content: flex-end;

        button {
          width: 80%;
          padding: 10px;
          font-size: 18px;
          border: 2px solid transparent;
          border-radius: 15px;
          color: $white;
          background-color: $deepBlue;
          transition: background-color 0.25s ease-in-out,
            border 0.25s ease-in-out;

          &:hover {
            background-color: transparent;
            color: $deepBlue;
            border: 2px solid $deepBlue;
          }
        }
      }
    }
  }
  .footer__bottom {
    // background-color: lightseagreen;
    height: 20%;
    width: 80%;
    @include flexbox;

    .footer__bottom__links {
      // background-color: lightcoral;
      height: 100%;
      width: 50%;
      @include flexbox;

      .footer__bottom__copyright {
        // background-color: teal;
        height: 100%;
        width: 40%;
        @include flexbox;
        justify-content: space-between;
        padding-right: 30px;
        img {
          height: 40px;
        }
        p {
          color: $white;
          font-size: 14px;
        }
      }

      .footer__bottom__tags {
        // background-color: lightsteelblue;
        height: 100%;
        width: 60%;
        @include flexbox;
        .tag {
          text-decoration: none;
          color: $white;
          font-size: 14px;
          margin: 0px 10px;
          padding: 0px 5px;
        }

        .legal {
          border-left: 2px solid rgba($color: $lightBlue, $alpha: 0.5);
          border-right: 2px solid rgba($color: $lightBlue, $alpha: 0.5);
        }
      }
    }

    .footer__bottom__newsletter {
      //   background-color: lightgreen;
      height: 100%;
      width: 50%;

      @include flexbox;
      flex-direction: column;
      align-items: flex-end;
      p {
        color: $white;
        font-size: 20px;
      }

      .footer__bottom__newsletter__text {
        // background-color: red;
        width: 100%;
        @include flexbox;
        justify-content: flex-end;
        input {
          background-color: #474a5c;
          color: $white;
          width: 60%;
          height: 40px;
          padding: 10px;
          border: none;
          border-radius: 10px 0px 0px 10px;

          &::placeholder {
            color: $white;
          }
        }
        button {
          width: 20%;
          height: 40px;
          padding: 10px;
          border: none;
          border-radius: 0px 10px 10px 00px;
          background-color: $deepBlue;
          color: $white;
          font-size: 14px;
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .footer {
    height: 1000px;
    .footer__top {
      flex-direction: column-reverse;
      .footer__top__login {
        width: 100%;
        margin-bottom: 10px;
        .footer__top__login__heading {
          justify-content: center;
          margin-bottom: 10px;
        }

        .footer__top__login__text {
          align-items: center;
        }

        .footer__top__login__button {
          justify-content: center;
        }
      }
      .footer__top__links {
        flex-direction: column;
        width: 100%;
        .footer__top__company {
          margin-top: 50px;
        }
        .footer__top__language {
          margin-top: 10px;
          margin-bottom: 50px;
        }
      }
    }
    .footer__bottom {
      flex-direction: column-reverse;
      .footer__bottom__newsletter {
        width: 100%;
        align-items: center;

        .footer__bottom__newsletter__heading {
          margin: 10px 0px;
        }
        .footer__bottom__newsletter__text {
          justify-content: center;
          input {
            width: 65%;
          }
          button {
            width: 35%;
          }
        }
      }
      .footer__bottom__links {
        flex-direction: column-reverse;
        width: 100%;
        .footer__bottom__tags {
          width: 100%;
          margin: 10px 0px;
        }
        .footer__bottom__copyright {
          width: 100%;
          justify-content: center;

          img {
            margin-right: 20px;
          }
          p {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
