/* ----- News ----- */
@import "../../Theme.scss";

.news {
  .news__bg {
    height: 100vh;
    background-image: url("../BackgroundImages/NewsBG.png");
    background-position: 50% 10%;
    background-repeat: no-repeat;

    @include flexbox;
    align-items: flex-start;
    .news__bg__wrapper {
      width: 80%;
      margin-top: 50px;
      .news__bg__title {
        // background-color: coral;
        width: 100%;
        height: 100px;
        @include flexbox;

        p {
          font-size: 50px;
          color: $white;
        }
      }
      .news__bg__paragraph {
        // background-color: lightgreen;
        width: 100%;
        height: 100px;
        @include flexbox;
        p {
          text-align: center;
          color: $white;
        }
      }
      .news__bg__search {
        // background-color: lightcyan;
        width: 100%;
        height: 200px;
        @include flexbox;
        .news__bg__searchbox {
          // background-color: lightgreen;
          height: 40px;
          width: 40%;
          @include flexbox;
          input {
            height: 100%;
            width: 100%;
            color: $grey;
            border: 1px solid rgba($color: $grey, $alpha: 0.5);
            border-radius: 15px 0px 0px 15px;
            padding: 0px 10px;
          }
        }
        .news__bg__searchimage {
          // background-color: lightpink;
          background-color: $lightBlue;
          height: 40px;
          width: 40px;
          border-radius: 0px 15px 15px 0px;
          @include flexbox;

          img {
            height: 24px;
          }
        }
      }
    }
  }
  .news__articles {
    // background-color: lightseagreen;
    width: 80%;
    min-height: 1000px;
    margin: 10px auto;

    @include flexbox;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-basis: 25%;

  }
}
// Width
@media (max-width: 800px) {
  .news {
    .news__bg {
      .news__bg__wrapper {
        .news__bg__paragraph {
          height: 150px;
        }
        .news__bg__search {
          height: 100px;
          .news__bg__searchbox {
            width: 80%;
          }
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .news {
    .news__bg {
      .news__bg__wrapper {
        .news__bg__paragraph {
          height: 200px;
        }
        .news__bg__search {
          height: 100px;
          .news__bg__searchbox {
            width: 80%;
          }
        }
      }
    }
  }
}

// Height
@media (max-height: 400px) {
  .news {
    .news__bg {
      height: 150vh;
    }
  }
}
