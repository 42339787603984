/* ----- Navbar ----- */
@import "../Theme.scss";

.navbar {
  background-color: $deepBlue;
  width: 100%;
  height: 125px;
  @include flexbox;
  flex-direction: column;
  position: fixed;
  transition: all 0.25s ease-in-out;
  z-index: 10;
  .navbar__top {
    @include flexbox;
    width: 100%;
    height: 40px;
    // background-color: $deepBlue;
    // background-color:$lightBlue;

    .navbar__top__container {
      @include flexbox;
      justify-content: flex-end;
      width: 80%;
      height: 40px;
      // border-bottom: 1px solid rgba($color: $lightBlue, $alpha: 0.5);

      .navbar__contact {
        @include flexbox;
        // background-color: lightcoral;

        margin-left: 30px;

        .navbar__contact__image {
          @include flexbox;
        }
        .navbar__contact__image img {
          height: 15px;
        }

        .navbar__contact__text p {
          color: $white;
          font-size: 14px;
          padding: 0px 10px;
        }
      }
      .navbar__language {
        @include flexbox;
        // background-color: lightgray;
        margin-left: 30px;

        .navbar__language__image {
          @include flexbox;
        }
        .navbar__language__image img {
          height: 15px;
        }
        .navbar__language__text p {
          color: $white;
          font-size: 14px;
          padding: 0px 10px;
        }
      }
      .navbar__login {
        @include flexbox;
        // background-color: lightgreen;
        margin-left: 30px;

        .navbar__login__image {
          @include flexbox;
        }
        .navbar__login__image img {
          height: 15px;
        }
        .navbar__login__text p {
          color: $white;
          font-size: 14px;
          padding: 0px 0px 0px 10px;
        }
      }
    }
  }

  .navbar__bottom {
    @include flexbox;
    width: 100%;
    height: 85px;
  }

  .navbar__bottom__container {
    @include flexbox;
    justify-content: flex-end;
    width: 80%;
    height: 100%;

    // background-color: lightgreen;

    .navbar__bottom__logo {
      // background-color: lightcoral;
      width: 15%;

      img {
        height: 50px;
      }
    }

    .navbar__bottom__links {
      // background-color: teal;
      width: 85%;

      ul {
        @include flexbox;
        justify-content: flex-end;
        list-style: none;
      }

      .navlink {
        color: $white;
        border-radius: 20px;
        padding: 5px 15px;
        text-decoration: none;
        transition: background-color 0.25s ease-in-out;

        &:hover {
          background-color: $white;
          color: $deepBlue;
        }
      }

      .dropdown {
        position: relative;

        .droplink {
          font-size: 14px;
          width: 300px;
          position: absolute;

          color: $white;
          text-decoration: none;

          .droplink__bar {
            background-color: $white;
            padding: 8px 20px;
            width: 100%;
          }

          .droplink__item {
            color: $grey;
            text-decoration: none;

            &:hover {
              color: $deepBlue;
            }
          }
        }
      }
    }

    .navbar__bottom__menu {
      height: 100%;
      width: 50px;

      // background-color: lightcoral;

      @include flexbox;
      display: none;
      img {
        height: 30px;
        width: 30px;
      }
    }
  }
  .navbar__bottom__mobile {
    // background-color: $lightBlue;
    position: absolute;
    // display: none;
    top: 125px;
    width: 100vw;
    transition: all 0.5s ease-in-out;
  }
}

// ACTIVE-----------------------------------------------------------------------
.navbaractive {
  background-color: $white;
  width: 100%;
  height: 125px;
  @include flexbox;
  flex-direction: column;
  position: fixed;
  transition: all 0.25s ease-in-out;
  box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.2);
  z-index: 10;
  .navbar__top {
    @include flexbox;
    display: none;
    width: 100%;
    height: 40px;
    // background-color: $deepBlue;
    // background-color:$lightBlue;

    .navbar__top__container {
      @include flexbox;
      justify-content: flex-end;
      width: 80%;
      height: 40px;
      border-bottom: 1px solid rgba($color: $lightBlue, $alpha: 0.5);

      .navbar__contact {
        @include flexbox;
        // background-color: lightcoral;

        margin-left: 30px;

        .navbar__contact__image {
          @include flexbox;
        }
        .navbar__contact__image img {
          height: 15px;
        }

        .navbar__contact__text p {
          color: $deepBlue;
          font-size: 14px;
          padding: 0px 10px;
        }
      }
      .navbar__language {
        @include flexbox;
        // background-color: lightgray;
        margin-left: 30px;

        .navbar__language__image {
          @include flexbox;
        }
        .navbar__language__image img {
          height: 15px;
        }
        .navbar__language__text p {
          color: $deepBlue;
          font-size: 14px;
          padding: 0px 10px;
        }
      }
      .navbar__login {
        @include flexbox;
        // background-color: lightgreen;
        margin-left: 30px;

        .navbar__login__image {
          @include flexbox;
        }
        .navbar__login__image img {
          height: 15px;
        }
        .navbar__login__text p {
          color: $deepBlue;
          font-size: 14px;
          padding: 0px 0px 0px 10px;
        }
      }
    }
  }

  .navbar__bottom {
    @include flexbox;
    width: 100%;
    height: 85px;
  }

  .navbar__bottom__container {
    @include flexbox;
    justify-content: flex-end;
    width: 80%;
    height: 100%;

    // background-color: lightgreen;

    .navbar__bottom__logo {
      // background-color: lightcoral;
      width: 15%;

      img {
        height: 50px;
      }

    }

    .navbar__bottom__links {
      // background-color: teal;
      width: 85%;

      ul {
        @include flexbox;
        justify-content: flex-end;
        list-style: none;
      }

      .navlink {
        color: $grey;
        border-radius: 20px;
        padding: 5px 15px;
        text-decoration: none;
        transition: background-color 0.25s ease-in-out;

        &:hover {
          background-color: $fadedBlue;
          // color: $deepBlue;
        }
      }

      .dropdown {
        position: relative;

        .droplink {
          font-size: 14px;
          width: 300px;
          position: absolute;

          color: $white;
          text-decoration: none;

          .droplink__bar {
            background-color: $white;
            padding: 8px 20px;
            width: 100%;
          }

          .droplink__item {
            color: $grey;
            text-decoration: none;

            &:hover {
              color: $deepBlue;
            }
          }
        }
      }
    }

    .navbar__bottom__menu {
      height: 100%;
      width: 50px;

      // background-color: lightcoral;

      @include flexbox;
      display: none;
      img {
        height: 30px;
        width: 30px;
      }
    }
  }
  .navbar__bottom__mobile {
    background-color: $lightBlue;
    position: absolute;
    // display: none;
    top: 125px;
    width: 100%;
    transition: all 0.5s ease-in-out;
  }
}

@media (max-width: 1200px) {
  .navbar__bottom__links {
    ul li {
      display: none;
    }
  }
  .navbar .navbar__bottom__container .navbar__bottom__menu {
    display: flex;
  }
  .navbaractive .navbar__bottom__container .navbar__bottom__menu {
    display: flex;
  }
}

@media (max-width: 600px) {
  .navbar {
    .navbar__top {
      .navbar__top__container {
        justify-content: space-between;

        .navbar__contact {
          margin-left: 0px;

          .navbar__contact__text p {
            font-size: 12px;
          }
        }
        .navbar__language {
          margin-left: 0px;

          .navbar__language__text p {
            font-size: 12px;
          }
        }
        .navbar__login {
          margin-left: 0px;

          .navbar__login__text p {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media (max-width: 400px){
  .navbar {
    .navbar__top {
      display: none;
    }
  }
}
