/* ----- Booking Engine ----- */
@import "../../Theme.scss";

.booking {
  .booking__intro {
    // background-color: lightgreen;
    height: 100vh;
    width: 100%;

    @include flexbox;
    flex-direction: column;
    justify-content: flex-start;

    .booking__intro__wrapper {
      //   background-color: magenta;
      width: 100%;
      height: 60%;
      margin-top: 50px;
      .booking__intro__title {
        // background-color: lightcoral;
        width: 80%;
        height: 30%;
        margin: 0px auto;
        @include flexbox;

        p {
          font-size: 36px;
          color: $deepBlue;
        }
      }
      .booking__intro__subtitle {
        // background-color: lightcyan;
        width: 80%;
        height: 20%;
        margin: 0px auto;
        @include flexbox;

        p {
          font-size: 20px;
          color: $grey;
          text-align: center;
        }
      }
      .booking__intro__paragraph {
        // background-color: lightslategray;
        width: 80%;
        height: 50%;
        margin: 0px auto;
        @include flexbox;

        p {
          font-size: 16px;
          text-align: center;
          color: $grey;
        }
      }
    }
  }

  .booking__benifits {
    height: 1000px;
    background-image: url("../BackgroundImages/BlueBG.svg");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    @include flexbox;

    .booking__benifits__container {
      width: 80%;
      height: 70%;
      //   background-color: lightskyblue;

      .booking__benifits__heading {
        height: 20%;
        width: 100%;
        // background-color: lightcoral;
        @include flexbox;
        p {
          font-size: 36px;
          color: $white;
          text-align: center;
        }
      }

      .booking__benifits__blocks {
        // background-color: lightgreen;
        height: 80%;
        @include flexbox;
        justify-content: space-between;
        .booking__benifits__block {
          //   background-color: lightsalmon;
          height: 400px;
          width: 30%;

          .booking__benifits__block__image {
            // background-color: lightseagreen;
            width: 100%;
            height: 40%;
            @include flexbox;
          }
          .booking__benifits__block__title {
            // background-color: mediumseagreen;
            width: 100%;
            height: 20%;
            @include flexbox;
            p {
              color: $white;
              font-size: 20px;
              text-align: center;
            }
          }
          .booking__benifits__block__paragraph {
            // background-color: peru;
            width: 100%;
            height: 40%;
            @include flexbox;
            p {
              color: $white;
              text-align: center;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .booking__laptop {
    // background-color: lightgreen;
    height: 500px;
    @include flexbox;

    img {
      //   background-color: lightcoral;
      transform: translateY(-150px);
      width: 80%;
    }
  }

  .booking__test {
    .booking__test__title {
      //   background-color: lightseagreen;
      width: 80%;
      height: 100px;
      margin: 0px auto;
      @include flexbox;

      p {
        color: $grey;
        font-size: 36px;
        text-align: center;
      }
    }
    .booking__test__paragraph {
      //   background-color: magenta;
      width: 80%;
      height: 50px;
      margin: 20px auto;
      @include flexbox;

      p {
        text-align: center;
        font-size: 18px;
        color: $grey;
      }
    }
  }

  .booking__calendar {
    background-color: $deepBlue;
    // background-color: coral;
    width: 100%;
    height: 300px;
    @include flexbox;
    .booking__calendar__left {
      width: 60%;
      height: 200px;
      // background-color: lightseagreen;
      .booking__calendar__top {
        // background-color: lightsteelblue;
        width: 100%;
        height: 150px;
        @include flexbox;
        //   position: relative;

        .booking__calendar__checkin {
          // background-color: lightseagreen;
          height: 100%;
          width: 40%;
        }
        .booking__calendar__checkout {
          // background-color: lime;
          height: 100%;
          width: 40%;
        }

        .booking__calendar__text {
          // background-color: lightgreen;
          height: 50px;

          @include flexbox;
          p {
            color: $white;
          }
        }

        .booking__calendar__textbox {
          height: 100px;
          // background-color: mediumpurple;
          @include flexbox;
          width: 250px;
          margin: 0px auto;
          position: relative;

          img {
            height: 30px;
          }

          input {
            width: 225px;
            padding: 10px;
            color: $grey;
            font-family: "poppins";
            margin-right: 20px;
          }

          .booking__calendar__checkin__container {
            position: absolute;
            top: 100px;
            z-index: 5;
            width: 275px;
          }
          .booking__calendar__checkout__container {
            position: absolute;
            top: 100px;
            z-index: 5;
            width: 275px;
          }
        }
      }

      .booking__calendar__bottom {
        // background-color: lightgray;
        width: 100%;
        // height: 50px;
        @include flexbox;
        justify-content: flex-start;
        img {
          margin: 10px;
        }
        a {
          text-decoration: none;
          color: $white;
        }
      }
    }
    .booking__calendar__right {
      width: 20%;
      height: 200px;
      // background-color: limegreen;
      .booking__calendar__search {
        // background-color: mediumorchid;
        height: 100%;
        width: 100%;
        @include flexbox;

        button {
          padding: 10px;
          width: 200px;
          color: $white;
          border: none;
          background-color: #f7971c;
          cursor: pointer;
          font-size: 16px;
        }
      }
    }
  }

  .booking__highlights {
    // background-color: lightcoral;

    .booking__highlights__title {
      //   background-color: lightseagreen;
      width: 80%;
      height: 200px;
      margin: 0px auto;
      @include flexbox;

      p {
        color: $grey;
        font-size: 36px;
        text-align: center;
      }
    }
    .booking__highlights__subtitle {
      //   background-color: magenta;
      width: 80%;
      height: 100px;
      margin: 0px auto;
      @include flexbox;

      p {
        text-align: center;
        font-size: 30px;
        color: $deepBlue;
      }
    }
    .booking__highlights__paragraph {
      //   background-color: mediumpurple;
      width: 80%;
      margin: 0px auto;
      @include flexbox;
      height: 75px;

      p {
        text-align: center;
        font-size: 18px;
        color: $grey;
      }
    }

    .booking__highlights__image {
      //   background-color: lightgreen;
      @include flexbox;
      height: 600px;
      img {
        // background-color: lightcoral;
        height: 500px;
      }
    }
  }

  .booking__bottom {
    // background-color: lightslategray;
    .booking__bottom__title {
      //   background-color: magenta;
      width: 80%;
      height: 100px;
      margin: 0px auto;
      @include flexbox;

      p {
        color: $deepBlue;
        font-size: 36px;
        text-align: center;
      }
    }
    .booking__bottom__paragraph {
      //   background-color: lightcoral;
      height: 200px;
      width: 80%;
      margin: 0px auto;
      @include flexbox;

      p {
        color: $grey;
        font-size: 18px;
        text-align: center;
      }
    }
    .booking__bootom__image {
      //   background-color: lightgreen;
      @include flexbox;
      width: 80%;
      height: 300px;
      margin: 0px auto;
      img {
        width: 100%;
      }
    }
  }

  .booking__started {
    // background-color: red;
    height: 400px;
    // width: 100%;
    background-image: url("../BackgroundImages/BookingEngineBottomBanner.png");
    background-position: 50% 0%;
    background-size: cover;
    background-repeat: no-repeat;
    @include flexbox;
    flex-direction: column;

    .booking__started__button {
      //   background-color: lightgreen;
      width: 80%;
      height: 60%;
      @include flexbox;

      button {
        border: 3px solid $white;
        width: 200px;
        height: 50px;
        background-color: $white;
        padding: 5px;
        border-radius: 20px;
        color: $deepBlue;
        font-size: 20px;
        cursor: pointer;
        transition: background-color 0.5s ease-in-out;
        &:hover {
          color: $white;
          background-color: transparent;
        }
      }
    }
  }
}

// width

@media (max-width: 1000px) {
  .booking {
    .booking__calendar {
      height: 800px;
      flex-direction: column;
      .booking__calendar__left {
        height: 400px;
        width: 80%;
        .booking__calendar__top {
          height: 400px;
          flex-direction: column;

          .booking__calendar__checkin {
            width: 100%;
          }
          .booking__calendar__checkout {
            width: 100%;
          }
        }
        .booking__calendar__bottom {
          @include flexbox;
        }
      }
      .booking__calendar__right {
        width: 80%;
        height: 100px;
        margin-top: 100px;
        .booking__calendar__textbox {
          input {
            width: 70%;
          }
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .booking {
    .booking__intro {
      .booking__intro__wrapper {
        margin-top: 10px;
        height: 80%;
      }
    }

    .booking__benifits {
      height: 1500px;
      background-image: none;
      background-color: $deepBlue;
      .booking__benifits__container {
        height: 90%;
        .booking__benifits__heading {
          height: 5%;
        }
        .booking__benifits__blocks {
          flex-direction: column;
          height: 95%;
          .booking__benifits__block {
            width: 100%;
          }
        }
      }
    }

    .booking__laptop {
      height: 300px;
      img {
        transform: translateY(-100px);
      }
    }

    .booking__highlights {
      .booking__highlights__title {
        p {
          font-size: 24px;
        }
      }
      .booking__highlights__subtitle {
        p {
          font-size: 20px;
        }
      }
      .booking__highlights__paragraph {
        height: 150px;
        p {
          font-size: 16px;
        }
      }
      .booking__highlights__image {
        height: 450px;
        img {
          height: 350px;
        }
      }
    }

    .booking__bottom {
      .booking__bottom__title {
        p {
          font-size: 30px;
        }
      }
      .booking__bottom__paragraph {
        p {
          font-size: 14px;
        }
      }
      .booking__bootom__image {
        height: 200px;
      }
    }
  }
}
@media (max-width: 600px) {
  .booking {
    .booking__intro {
      .booking__intro__wrapper {
        height: 90%;
        margin-top: 0px;
        .booking__intro__subtitle {
          p {
            font-size: 16px;
          }
        }
        .booking__intro__paragraph {
          p {
            font-size: 14px;
          }
        }
      }
    }
    .booking__test {
      .booking__test__title {
        height: 100px;

        p {
          font-size: 36px;
        }
      }
      .booking__test__paragraph {
        height: 100px;

        p {
          font-size: 16px;
        }
      }
    }
    .booking__highlights {
      .booking__highlights__image {
        height: 450px;
        img {
          height: 250px;
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .booking {
    .booking__laptop {
      height: 100px;
      img {
        transform: translateY(-50px);
      }
    }

    .booking__highlights {
      .booking__highlights__image {
        height: 400px;
        img {
          height: 200px;
        }
      }
    }

    .booking__bottom {
      .booking__bottom__title {
        p {
          font-size: 24px;
        }
      }
      .booking__bottom__paragraph {
        p {
          font-size: 14px;
        }
      }

      .booking__bootom__image {
        height: 100px;
      }
    }
  }
}
// Height
@media (max-height: 400px) {
  .booking {
    .booking__intro {
      height: 150vh;
      .booking__intro__wrapper {
        margin-top: 10px;
        height: 80%;
      }
    }
  }
}
