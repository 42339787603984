/* ----- News ----- */
@import "../../Theme.scss";

.article {
  width: 300px;
  height: 525px;
  // background-color: lightgreen;
  margin: 20px 10px;
  box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  .article__image {
    // background-color: lightcoral;
    width: 100%;
    @include flexbox;
    height: 200px;
    img {
      width: 100%;
    }
  }
  .article__text {
    // background-color: lightskyblue;
    .article__date {
      //   background-color: mediumseagreen;
      @include flexbox;
      height: 30px;
      justify-content: flex-start;
      img {
        // background-color: coral;
        height: 16px;
        margin: 0px 10px;
      }
      p {
        // background-color: mediumpurple;
        color: $grey;
      }
    }
    .article__title {
      //   background-color: navajowhite;
      @include flexbox;
      align-items: flex-start;
      padding: 10px 10px;
      height: 100px;

      p {
        font-size: 18px;
        color: $deepBlue;
      }
    }
    .article__subtitle {
      //   background-color: lightslategray;
      height: 120px;
      p {
        font-size: 14px;
        text-align: justify;
        padding: 10px 10px;
        color: $grey;
      }
    }
    .article__button {
      //   background-color: coral;
      height: 50px;
      @include flexbox;

      button {
        padding: 10px;
        width: 150px;
        font-size: 14px;
        border: 2px solid $deepBlue;
        border-radius: 20px;
        color: $deepBlue;
        cursor: pointer;
        background-color: $white;
        transition: background-color 0.5s ease-in-out;

        &:hover {
          color: $white;
          background-color: $deepBlue;
        }
      }
    }
  }
}
// Width
@media (max-width: 800px) {
  .article {
    width: 100%;
    height: 800px;
    .article__image {
      height: 400px;
    }
    .article__text {
      height: 400px;
    }
  }
}
@media (max-width: 600px) {
  .article {
    width: 100%;
    height: 700px;
    .article__image {
      height: 300px;
    }
    .article__text {
      height: 400px;
    }
  }
}
@media (max-width: 400px) {
  .article {
    width: 100%;
    height: 600px;
    .article__image {
      height: 250px;
    }
    .article__text {
      height: 350px;
    }
  }
}
